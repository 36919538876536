export default [
    {
        header: 'Ejecutante'
    },
    {
        title: 'Dashboard',
        route: 'dashboard-ejecutante',
        icon: 'GridIcon'
    },
    {
        title: 'Gestión de Tareas',
        route: 'tareas',
        icon: 'InboxIcon'
    },

]
