/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'



//2023-02-09 | fg | opciones de menu
import solicitante from '@/modules/solicitante/navigation/solicitante'
import ejecutante from '@/modules/ejecutante/navigation/ejecutante'
import seguridad from '@/modules/seguridad/navigation/seguridad'

// Array of sections
export default [
    ...solicitante,
    ...ejecutante,
    ...seguridad,

    ...dashboard,
    ...appsAndPages,
    ...uiElements,
    ...formAndTable,
    ...chartsAndMaps,
    ...others

]
