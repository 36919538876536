export default [
  {
    header: 'Seguridad'
  },
  {
    title: 'Auditoría',
    route: 'seguridad-auditoria',
    icon: 'HomeIcon'
  },
  {
    title: 'Reportes',
    icon: 'SearchIcon',
    children: [
      {
        title: 'Colaboradores',
        route: 'consultas-colaborador',
        icon: 'UsersIcon'
      },
    ]
  },
  {
    title: 'Catálogos',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Usuarios',
        route: 'catalogo-usuarios',
        icon: 'UsersIcon'
      },
      {
        title: 'Oficinas',
        route: 'catalogo-oficinas',
        icon: 'MapPinIcon'
      },
      {
        title: 'Cargos',
        route: 'catalogo-cargos',
        icon: 'ListIcon'
      },
      {
        title: 'Colaboradores',
        route: 'catalogo-colaborador',
        icon: 'UserCheckIcon'

      },
      {
        title: 'Aplicaciones',
        route: 'catalogo-aplicaciones',
        icon: 'BoxIcon'
      }
    ]
  },

]
