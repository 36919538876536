export default [
    {
        header: 'Solicitante'
    },
    {
        title: 'Inicio',
        route: 'dashboard-solicitante',
        icon: 'HomeIcon'
    },
    {
        title: 'Solicitudes',
        route: 'solicitudes-menu',
        icon: 'FileTextIcon'
    },
    {
        title: 'Subir Documentos',
        route: 'documentos-carga',
        icon: 'UploadIcon'
    },
]
